<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/jk_city.jpg')"
      class="white--text top-margin"
      gradient="to bottom, rgba(85, 85, 95, .8), rgba(0,0,0,.75)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <!--<base-heading
            size="text-h2"
            title="Metarhythm"
            weight="medium"
          />
          -->
          <base-subheading
            weight="regular"
            title="We love making things"
          />
          <base-img
            :src="require('@/assets/logo.svg')"
            max-width="600"
            contain
          />
          <base-body>
            We are experienced, passionate technologists who design, code, and run apps and systems.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <!--
            <base-btn>
              More
            </base-btn>
            -->
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  // gradient="to bottom, rgba(55, 55, 65, .9), rgba(0,0,0,.4)"

  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - 80 - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>

<style lang="sass">
  .top-margin
    margin-top: -80px

  #home-subhead
    font-size: 30px !important
</style>
